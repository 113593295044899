<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="名称">
          <el-input v-model="searchData.name" size="small" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.org_list_add)"
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="$router.push({ name: 'OrgAdd' })"
        >新增</el-button
      >
    </div>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getListAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '名称',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },

  {
    label: '操作',
    fixed: 'right',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.org_list_edit)}
            style="margin-right: 10px;"
            type="primary"
            onClick={() =>
              this.$router.push({
                name: 'OrgEdit',
                params: { id: row['id'] || undefined }
              })
            }
          >
            编辑
          </el-link>
          <el-link v-show={this.isShowBtn(this.AUTH_BTN.org_list_del)} type="primary" onClick={() => this.handleDel(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      searchData: {
        name: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let pageParams = { page: this.currentPage, page_size: this.page_size }
      let params = Object.assign(pageParams, this.searchData)
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleDel(id) {
      this.$confirm('此操作将永久删除该调解员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .header-btn {
    margin-bottom: 10px;
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
